<template>
  <!-- 明細 -->
  <div>
    <div class="addPerson__wrap" v-show="visible">
      <el-header class="shadow page-header__wrap">
        <div class="flex">
          <div class="backBtn" @click="handleReturn">
            <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
            <span>返回</span>
          </div>
          <div class="title">
            <span class="site">当前位置：</span>
            {{ $route.meta.title }}
            <span> > </span>
            查看明细
          </div>
        </div>
      </el-header>
      
      <div class="main">
        <div class="mainBox" style="display: flex;flex-direction: column;">
          <div style="display:flex;align-items: center;margin-bottom:10px;font-size:14px;">
            <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
              <el-form-item label="日期" prop="day">
                <el-date-picker
                  v-model="table.params.day"
                  type="date"
                  placeholder="请选择"
                  format="yyyy 年 MM 月 dd"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  @change="onSearch">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="商品名称" prop="goods_name">
                <el-input
                  size="mini"
                  v-model.trim="table.params.goods_name"
                  placeholder="请输入商品名称"
                  clearable
                  style="width:220px;"
                  @clear="onSearch"
                  @keydown.enter.native="onSearch"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="resetSel">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div style="margin-bottom: 10px;color:#f56c6c;font-size:14px;font-weight:bold;">
            汇总：
            <span style="margin-right:20px;">下单数量:{{table.total_quantity}}</span>
            <span style="margin-right:20px;">销售金额:￥{{table.total_amount}}</span>
            <span style="margin-right:20px;">采购金额:￥{{table.total_purchase_price}}</span>
            <span style="margin-right:20px;">毛利:￥{{table.total_profit}}</span>
          </div>
          <div ref="tableDom" style="flex:1;">
            <el-table
              :data="table.data"
              style="width: 100%"
              :row-key="row => row.id"
              border
              size="mini"
              :height="height"
              :header-cell-style="{background:'#f5f5f5'}">
              <el-table-column
                type="index"
                label="序号"
                width="60">
              </el-table-column>
              <el-table-column property="date" label="配送日期"></el-table-column>
              <el-table-column property="goods_name" label="货物名称"></el-table-column>
              <el-table-column property="unit_name" label="单位"></el-table-column>
              <el-table-column property="spec_name" label="规格"></el-table-column>
              <el-table-column property="total_quantity" label="下单数量"></el-table-column>
              <el-table-column property="avg_amount" label="销售单价(元)"></el-table-column>
              <el-table-column property="total_amount" label="销售金额(元)"></el-table-column>
              <el-table-column property="purchase_price" label="采购单价(元)"></el-table-column>
              <el-table-column property="total_purchase_price" label="采购金额(元)"></el-table-column>
              <el-table-column property="profit" label="毛利(元)">
                <template slot-scope="{row}">
                  <span :class="Number(row.profit) < 0 ? 'redColor' : ''">{{row.profit}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pageWrap" v-if="table.total > 10">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="table.params.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="table.params.count"
              layout="total, sizes, prev, pager, next, jumper"
              :total="table.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'BuillDetail',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        height: 0,
        table: {
          params:{
            page:1,
            count:20,
            year: '',
            month: '',
            quarter: '',//季度
            day: '',
            address_id: '',
            goods_name: '',
          },
          total: 0,
          data:[],
          total_quantity: '', // 下单数量
          total_amount: '', // 销售金额
          total_purchase_price: '', // 采购金额
          total_profit: '', // 毛利
        },
        currRow: {},
      }
    },
    methods: {
      getDetail(row) {
        this.currRow = row;
        this.getTable()
        this.$nextTick(() => {
          this.height = this.$refs.tableDom.offsetHeight - 40 || 500
        })
      },
      reset(done) {
        this.isChange = false;
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      resetSel() {
        this.$refs.elFormDom.resetFields()
        this.table.params.goods_name = '';
        this.table.params.day = '';
        this.table.params.page = 1;
        this.table.params.count = 20;
        this.getTable();
      },
      // 详情
      getTable() {
        let _params = {
          address_id: this.currRow.address_id, // 门店ID
          year: this.currRow.year, // 下单年份
          month: this.currRow.month, // 下单月份
          quarter: this.currRow.quarter, // 季度
          goods_name: this.table.params.goods_name, // 商品名称
          day: this.table.params.day, // 日期
          page: this.table.params.page,
          count: this.table.params.count,
        }
        this.$http.get("/admin/bill/merchantLog",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list;
            this.table.total = res.data.total;
            this.table.total_quantity = res.data.total_quantity; //下单数量
            this.table.total_amount = res.data.total_amount; //销售金额
            this.table.total_purchase_price = res.data.total_purchase_price; //采购金额
            this.table.total_profit = res.data.total_profit; //毛利
          }
        })
      },
      // 返回
      handleReturn() {
        this.$refs.elFormDom.resetFields()
        this.table.params.goods_name = '';
        this.table.params.day = '';
        this.table.params.page = 1;
        this.table.params.count = 20;
        this.$emit("refresh")
        this.toggle(false)
        this.table.data = [];
      },
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.pageWrap{
  margin-top: 10px;
  text-align: right;
  margin-right: 50px;
}
</style>
